import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <h3>Contact</h3>
    <p>Welcome to Contact Page. For now, there is no published method for contact.</p>
  </Layout>
)

export default ContactPage
